.reviews {
    padding: 50px 0 109px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>* {
        color: var(--white);
    }

    h2 {
        padding: 0 10px 10px 10px;
        font-size: 25px;
        font-weight: 700;
        line-height: 34px;
        border-bottom: 3px solid var(--white);
    }

    &-list {
        position: relative;
        margin-top: 50px;

        width: 100%;

        .slick-arrow {
            display: none !important;
        }

        .slick-dots {
            position: absolute;

            bottom: -70px;
        }

        .slick-dots li button {
            padding: 0 5px;
        }
        
        .slick-dots li button:before {
            width: 10px;
            height: 10px;
            
            background-color: var(--white);
        }

        .slick-dots li.slick-active button:before {
            background-color: var(--black-100);
        }

        .slick-slide {
            margin: 0 20px !important;
        }
    }

    &-list__item {
        display: flex !important;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: 30px;


        &-img {
            display: flex;
            width: 134.23px;
            height: 134.23px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &-info {
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

            div h3 {
                font-size: 20px;
                font-weight: 700;
                line-height: 27px;
            }

            div span {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                line-height: 27px;
            }

            p {
                margin-top: 30px;

                font-size: 18px;
                font-weight: 600;
                line-height: 25px;

                @include rmin($tablet) {
                    text-align: center;
                }
            }
        }
    }
}

@include rmin($desktop) {
    .reviews {
        padding: 50px 0 100px;

        h2 {
            padding: 10px;
            font-size: 42px;
            line-height: 57px;
        }

        &-list {
            margin: 50px auto auto;
            width: 80%;

            .slick-arrow {
                display: flex !important;

                top: 68px;
                margin-top: 0;

                &.slick-next {
                    right: -60px;

                    &::before {
                        transform: rotate(180deg);
                    }
                }

                &.slick-prev {
                    left: -100px;
                }

                &::before {
                    content: "" !important;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    width: 61px;
                    height: 61px;
                    background: url('../img/icons/arrow-nav.svg');
                    background-size: 61px 61px;
                    background-position: center;
                    background-repeat: no-repeat;
                    opacity: 1;
                }
            }

            .slick-dots {
                display: none !important;
            }
        }

        &-list__item {
            flex-direction: row;

            &-img {
                width: 180px;
                height: 180px;
                flex-shrink: 0;
            }

            &-info {
                div {
                    align-items: flex-start;
                }

                div span {
                    text-align: start;
                }

                p {
                    padding-right: 21px;
                    text-align: start;
                }
            }
        }
    }
}

@include rmin($desktop-lg) {
    .reviews {
        &-list {
            width: 1161px;

            .slick-slide {
                margin: 0 0 !important;
            }

            .slick-arrow.slick-prev {
                left: -184px;
            }

            .slick-arrow.slick-next {
                right: -143px;
            }
        }

        &-list__item {
            gap: 83px;

            &-img {
                width: 253.72px;
                height: 253.72px;
            }

            &-info {
                div h3 {
                    font-size: 30px;
                    line-height: 41px;
                }

                div span {
                    font-size: 25px;
                    line-height: 34px;
                }

                p {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 27px;
                }
            }
        }
    }
}