.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 13px 0;

    width: 100%;
    height: 65px;

    border-radius: 100px;

    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    text-decoration: none;
    transition: .3s;

    &--primary {
        background-color: var(--primary-500);
        color: var(--white);

        &:hover {
            background-color: #8D0310;
        }

        &.disabled {
            background-color: #C93F4C;
            cursor: none;
            pointer-events: none;
        }
    }
}

@include rmin($desktop) {
    .btn {
        width: fit-content;
        padding: 13px 50px;
    }
}

@include rmin($desktop-lg) {
    .btn {
        min-width: 621px;
        width: unset;
        height: 101px;

        font-size: 30px;
        line-height: 41px;
    }
}