@charset "UTF-8";
:root { --black: #000000; --white: #FFFFFF; --primary-500: #AB212E; --hover: #8D0310; --light: #F5F5F4; --black-100: #1E1C1C; --yellow: #FFD503; --br--3: 3px; --br--5: 5px; --br--7: 7px; --br--6: 6px; --br--8: 8px; --br--10: 10px; --br--12: 12px; --br--15: 15px; --br--16: 16px; --br--20: 20px; --br--25: 25px; --br--30: 30px; --br--66: 66px; }

.icon-facebook { width: 0.53em; height: 1em; fill: initial; }

.icon-facebook { width: 0.53em; height: 1em; fill: initial; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-ExtraLight.ttf"); font-weight: 200; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-Light.ttf"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-Regular.ttf"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-Medium.ttf"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-SemiBold.ttf"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-Bold.ttf"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-Black.ttf"); font-weight: 800; font-style: normal; font-display: swap; }

@font-face { font-family: ns; src: url("../fonts/NunitoSans-ExtraBold.ttf"); font-weight: 900; font-style: normal; font-display: swap; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Slider */
.slick-loading .slick-list { background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face { font-family: "slick"; src: url("../fonts/slick.eot"); src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }

/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -10px; padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dots { position: absolute; bottom: 22px; list-style: none; display: block; text-align: center; padding: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; cursor: pointer; }

.slick-dots li button { display: block; padding: 3px; background: none; outline: none; line-height: 0px; font-size: 0px; color: red; cursor: pointer; }

.slick-dots li button:before { content: ""; display: block; height: 10px; width: 10px; box-sizing: border-box; border-radius: 50%; background: #979795; transition: all 0.1s ease; }

.slick-dots li button:hover:before { background: #4E4E4E; }

.slick-dots li.slick-active button:before { background: #4E4E4E; }

body { font-weight: 400; font-size: 16px; line-height: 1.4; font-family: "ns", sans-serif; color: var(--black-100); background-color: var(--white); }

body.lock { overflow: hidden; }

.container { max-width: 1529px; margin-left: auto; margin-right: auto; padding: unset; }

.section { padding-left: 26px; padding-right: 26px; }

.section--filled { background-color: var(--light); }

.section--filled-black { background-color: var(--black-100); }

.section--filled-primary { background-color: var(--primary-500); }

.section h1, .section h2, .section h3, .section h4, .section h5 { font-weight: 500; }

.section a { text-decoration: none; }

.header { display: flex; align-items: center; justify-content: center; padding: 40px 0 0; }

.header-wrapper__logo { width: 118.13px; height: 36.76px; }

.header-wrapper__logo img { width: 100%; }

@media only screen and (min-width: 1024px) { .header { align-items: flex-start; justify-content: flex-start; margin: auto; max-width: 1530px; padding: 40px 35px 0; }
  .header-wrapper__logo { width: 198px; height: 61.62px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .header { padding: 40px 0 0; } }

.hero { position: relative; margin-top: -77px; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 126px 0 50px; overflow: hidden; }

.hero > * { padding: 0 26px; color: var(--white); text-decoration: none; z-index: 2; }

.hero-top { text-align: center; }

.hero-top p { font-size: 18px; font-weight: 700; line-height: 25px; text-transform: inherit; }

.hero-title span { display: none; }

.hero-title h1 { margin-top: 50px; text-align: center; font-size: 30px; font-weight: 800; line-height: 41px; }

.hero-wrapper { margin-top: 50px; padding: unset; }

.hero-wrapper .btn { margin: auto; width: 85.3%; }

.hero-wrapper__list { margin-top: 50px; display: flex; flex-direction: column; gap: 10px; padding: 0 26px; }

.hero-wrapper__list li { display: flex; align-items: flex-start; gap: 10px; }

.hero-wrapper__list li img { margin-top: 2.5px; display: flex; flex-shrink: 0; width: 19px; height: 20px; }

.hero-wrapper__list li span { font-size: 18px; font-weight: 600; line-height: 25px; }

.hero-wrapper__info { position: relative; margin-top: 56px; display: flex; align-items: flex-start; padding: 20px 33px 22px 21px; background-color: var(--white); }

.hero-wrapper__info-descr { display: flex; flex-direction: column; gap: 15px; }

.hero-wrapper__info-descr p { font-size: 18px; line-height: 25px; }

.hero-wrapper__info-descr p:first-child { padding-right: 100px; font-weight: 600; color: var(--black-100); }

@media only screen and (min-width: 546px) { .hero-wrapper__info-descr p:first-child { padding-right: 110px; } }

.hero-wrapper__info-descr p:last-child { font-weight: 800; color: var(--primary-500); }

@media only screen and (min-width: 414px) { .hero-wrapper__info-descr p:last-child { padding-right: 110px; } }

.hero-wrapper__info-img { position: absolute; top: -70px; right: -16px; width: 185.81px; height: 214.16px; }

.hero-wrapper__info-img img { width: 100%; }

.hero-bottom { margin-top: 50px; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.hero-bottom img { width: 115px; height: 31.25px; }

.hero-bottom > span { text-align: center; margin-top: 50px; font-size: 20px; font-weight: 600; line-height: 27px; }

.hero-bottom__timer { margin-top: 20px; }

.hero-bottom__timer > span { font-size: 40px; font-weight: 600; line-height: 55px; }

.hero-bottom__timer img { display: none; }

.hero-bottom .btn { margin-top: 30px; }

.hero-bg { position: absolute; top: -45px; left: -51px; width: 1214.45px; height: 1278.58px; padding: unset; z-index: 1; }

.hero-bg img { width: 100%; }

@media only screen and (min-width: 767px) { .hero-wrapper { padding: 0  26px; }
  .hero-wrapper .btn { display: none; }
  .hero-wrapper__list { padding: unset; }
  .hero-wrapper__info { padding: 39px 230px 43px 80px; border-radius: 150px; }
  .hero-wrapper__info-descr p { padding-right: 0; } }

@media only screen and (min-width: 1024px) { .hero { margin-top: -102px; align-items: flex-start; justify-content: flex-start; padding: 64px 0 50px; }
  .hero-top { align-self: flex-end; padding: 0 30px 0 250px; }
  .hero-top p { text-align: end; text-transform: none; font-size: 40px; font-weight: 600; line-height: 55px; }
  .hero-top span { display: none; }
  .hero-title { margin-top: 1px; }
  .hero-title span { display: flex; font-size: 30px; font-weight: 700; line-height: 41px; }
  .hero-title h1 { margin-top: 42px; text-align: start; font-size: 50px; font-weight: 700; line-height: 72px; }
  .hero-wrapper { margin-top: 67px; display: flex; flex-direction: row; width: 100%; justify-content: space-between; align-items: center; gap: 20px; }
  .hero-wrapper__info { margin-top: 0; width: 50%; }
  .hero-wrapper__info-descr p:last-child { padding-right: 0; }
  .hero-wrapper__info-img { width: 231px; }
  .hero-wrapper__list { margin-top: 0; }
  .hero-wrapper__list li { align-items: center; }
  .hero-wrapper__list li img { width: 34px; height: 34px; }
  .hero-bottom { margin-top: 70px; flex-direction: row-reverse; gap: 20px; }
  .hero-bottom__arrow { display: none; }
  .hero-bottom__timer, .hero-bottom span, .hero-bottom .btn { margin-top: 0; }
  .hero-bottom span { text-align: start; }
  .hero-bottom__timer { display: flex; flex-direction: column; align-items: center; justify-content: center; }
  .hero-bottom__timer img { display: flex; }
  .hero-bottom__timer .arrow-white { margin-top: 10px; width: 100%; }
  .hero-bottom__timer .arrow-red { margin-top: 12px; width: 100%; }
  .hero-bg { width: 1114.87px; height: 1157.22px; top: -4px; right: -138px; left: unset; } }

@media only screen and (min-width: 1920px) { .hero { overflow: hidden; }
  .hero-top { padding: 0 0 0 300px; }
  .hero-title { padding: unset; }
  .hero-title h1 { font-size: 60px; font-weight: 800; line-height: 82px; }
  .hero-wrapper { padding: unset; gap: 123px; }
  .hero-wrapper__info { min-width: 800px; padding: 39px 164px 43px 80px; }
  .hero-wrapper__info-descr p { font-size: 24px; line-height: 33px; }
  .hero-wrapper__info-descr p:first-child { padding-right: 0; }
  .hero-wrapper__info-descr p:last-child { padding-right: 50px; }
  .hero-wrapper__info-img { width: 310.3px; height: 357.65px; top: -116px; right: -32px; }
  .hero-wrapper__list { margin-top: 17px; gap: 12px; }
  .hero-wrapper__list li span { font-size: 30px; font-weight: 400; line-height: 41px; }
  .hero-bottom { padding: unset; align-items: flex-start; gap: 0; }
  .hero-bottom__timer { margin: 18px 0 0 77px; }
  .hero-bottom__timer .arrow-white { margin-top: 0; width: 133px; }
  .hero-bottom__timer .arrow-red { width: 115px; }
  .hero-bottom > span { margin: 9.5px 0 0 52px; padding-right: 118px; font-size: 30px; font-weight: 300; line-height: 41px; } }

.advantages { padding: 40px 0 50px 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.advantages h2 { position: relative; padding: 0 10px 20px; font-size: 25px; font-weight: 700; line-height: 34px; text-align: center; border-bottom: 3px solid var(--primary-500); }

.advantages-list { margin-top: 56px; margin-bottom: 50px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 30px; }

.advantages-list__item { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 20px; }

.advantages-list__item div { display: flex; align-items: center; justify-content: center; flex-shrink: 0; width: 70px; height: 70px; background-color: var(--yellow); border-radius: 50%; }

.advantages-list__item div img { width: 51.75px; height: 51.75px; }

.advantages-list__item span { text-align: center; font-size: 18px; font-weight: 700; line-height: 25px; }

@media only screen and (min-width: 767px) { .advantages { align-items: flex-start; }
  .advantages-list { margin-top: 110px; margin-bottom: 100px; display: grid; grid-template-columns: repeat(2, 1fr); align-items: flex-start; justify-content: flex-start; } }

@media only screen and (min-width: 1024px) { .advantages { padding: 100px 0 123px; }
  .advantages h2 { font-size: 42px; line-height: 57px; padding: 10px; }
  .advantages h2::after { bottom: -10px; }
  .advantages-list { column-gap: 52px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .advantages-list { column-gap: 183px; row-gap: 60px; } }

@media only screen and (min-width: 1024px) { .advantages-list__item { align-items: flex-start; flex-direction: row; align-items: center; justify-content: flex-start; gap: 30px; }
  .advantages-list__item div { width: 100px; height: 100px; }
  .advantages-list__item div img { width: 72px; height: 72px; }
  .advantages-list__item span { text-align: start; font-size: 20px; line-height: 31px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .advantages-list__item { gap: 60px; }
  .advantages-list__item span { padding-right: 90px; font-size: 30px; line-height: 41px; } }

.speaker { display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 50px 0; }

.speaker-wrapper { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.speaker-wrapper__title { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 5px; }

.speaker-wrapper__title span { font-size: 18px; font-weight: 600; line-height: 25px; text-align: center; }

.speaker-wrapper__title h2 { font-size: 25px; font-weight: 700; line-height: 34px; padding: 10px; border-bottom: 3px solid var(--primary-500); }

.speaker-wrapper__img { position: relative; margin-top: 20px; width: 100%; }

.speaker-wrapper__img img { width: 100%; object-fit: cover; }

.speaker-wrapper__img::before { position: absolute; content: ''; bottom: 0; left: 0; width: 55%; height: 90%; background-color: var(--primary-500); z-index: -1; }

.speaker-wrapper__img::after { position: absolute; content: ''; bottom: 0; left: 0; width: 100%; height: 33px; background-color: #D9D9D9; }

@media only screen and (min-width: 546px) { .speaker-wrapper__img { display: flex; }
  .speaker-wrapper__img img { margin: auto; max-width: 509px; } }

.speaker-wrapper__list { margin-top: 30px; display: flex; flex-direction: column; gap: 20px; }

.speaker-wrapper__list-item { position: relative; margin-left: 25px; font-size: 18px; font-weight: 500; line-height: 25px; }

.speaker-wrapper__list-item::before { position: absolute; content: ''; top: 7px; left: -25px; width: 10px; height: 10px; background-color: var(--primary-500); border-radius: 50%; }

.speaker-list { margin-top: 30px; display: flex; flex-direction: column; gap: 20px; }

.speaker-list__item { display: flex; flex-direction: column; padding: 27px 30px; border: 3px solid var(--primary-500); border-radius: 17.83px; gap: 9px; }

.speaker-list__item span { font-size: 30px; font-weight: 700; line-height: 41px; }

.speaker-list__item p { font-size: 18px; font-weight: 600; line-height: 25px; }

@media only screen and (min-width: 1024px) { .speaker-wrapper { max-width: 1200px; position: relative; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: flex-end; }
  .speaker-wrapper__title { position: absolute; top: 0; left: 50%; }
  .speaker-wrapper__title.section { padding: unset; }
  .speaker-wrapper__list { margin-top: 99px; padding-right: 20px !important; gap: 15px; order: 2; width: 50%; }
  .speaker-wrapper__list.section { padding: unset; }
  .speaker-wrapper__img { order: 1; width: 50%; }
  .speaker-wrapper__img::after { width: 200%; }
  .speaker-list { flex-direction: row; } }

@media only screen and (min-width: 1920px) { .speaker { padding: 43px 0 99px; }
  .speaker-wrapper { max-width: unset; justify-content: flex-start; align-items: flex-start; }
  .speaker-wrapper__img { margin-top: 0; width: 804.51px; }
  .speaker-wrapper__img img { max-width: unset; }
  .speaker-wrapper__img::after { width: 190.1%; height: 59px; }
  .speaker-wrapper__title { margin-top: 58px; gap: 10px; left: 52.7%; }
  .speaker-wrapper__title span { font-size: 30px; line-height: 41px; }
  .speaker-wrapper__title h2 { font-size: 42px; line-height: 57px; }
  .speaker-wrapper__list { margin-top: 270px; width: 45.6%; gap: 30px; }
  .speaker-wrapper__list-item { margin-left: 59px; font-size: 20px; line-height: 27px; }
  .speaker-wrapper__list-item::before { width: 20px; height: 20px; top: 10px; left: -59px; }
  .speaker-list { margin-top: 60px; width: 100%; gap: 30px; }
  .speaker-list.section { padding: unset; }
  .speaker-list__item { padding: 31px 22px 30px 34px; gap: 11px; min-width: 360px; border-radius: 20px; }
  .speaker-list__item span { font-size: 42px; line-height: 57px; }
  .speaker-list__item p { font-size: 25px; line-height: 34px; } }

.reviews { padding: 50px 0 109px; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.reviews > * { color: var(--white); }

.reviews h2 { padding: 0 10px 10px 10px; font-size: 25px; font-weight: 700; line-height: 34px; border-bottom: 3px solid var(--white); }

.reviews-list { position: relative; margin-top: 50px; width: 100%; }

.reviews-list .slick-arrow { display: none !important; }

.reviews-list .slick-dots { position: absolute; bottom: -70px; }

.reviews-list .slick-dots li button { padding: 0 5px; }

.reviews-list .slick-dots li button:before { width: 10px; height: 10px; background-color: var(--white); }

.reviews-list .slick-dots li.slick-active button:before { background-color: var(--black-100); }

.reviews-list .slick-slide { margin: 0 20px !important; }

.reviews-list__item { display: flex !important; flex-direction: column; align-items: center; justify-content: center; gap: 30px; }

.reviews-list__item-img { display: flex; width: 134.23px; height: 134.23px; border-radius: 50%; overflow: hidden; }

.reviews-list__item-img img { width: 100%; object-fit: cover; }

.reviews-list__item-info div { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; }

.reviews-list__item-info div h3 { font-size: 20px; font-weight: 700; line-height: 27px; }

.reviews-list__item-info div span { text-align: center; font-size: 20px; font-weight: 500; line-height: 27px; }

.reviews-list__item-info p { margin-top: 30px; font-size: 18px; font-weight: 600; line-height: 25px; }

@media only screen and (min-width: 767px) { .reviews-list__item-info p { text-align: center; } }

@media only screen and (min-width: 1024px) { .reviews { padding: 50px 0 100px; }
  .reviews h2 { padding: 10px; font-size: 42px; line-height: 57px; }
  .reviews-list { margin: 50px auto auto; width: 80%; }
  .reviews-list .slick-arrow { display: flex !important; top: 68px; margin-top: 0; }
  .reviews-list .slick-arrow.slick-next { right: -60px; }
  .reviews-list .slick-arrow.slick-next::before { transform: rotate(180deg); }
  .reviews-list .slick-arrow.slick-prev { left: -100px; }
  .reviews-list .slick-arrow::before { content: "" !important; position: absolute; left: 0; top: 0; display: block; width: 61px; height: 61px; background: url("../img/icons/arrow-nav.svg"); background-size: 61px 61px; background-position: center; background-repeat: no-repeat; opacity: 1; }
  .reviews-list .slick-dots { display: none !important; }
  .reviews-list__item { flex-direction: row; }
  .reviews-list__item-img { width: 180px; height: 180px; flex-shrink: 0; }
  .reviews-list__item-info div { align-items: flex-start; }
  .reviews-list__item-info div span { text-align: start; }
  .reviews-list__item-info p { padding-right: 21px; text-align: start; } }

@media only screen and (min-width: 1920px) { .reviews-list { width: 1161px; }
  .reviews-list .slick-slide { margin: 0 0 !important; }
  .reviews-list .slick-arrow.slick-prev { left: -184px; }
  .reviews-list .slick-arrow.slick-next { right: -143px; }
  .reviews-list__item { gap: 83px; }
  .reviews-list__item-img { width: 253.72px; height: 253.72px; }
  .reviews-list__item-info div h3 { font-size: 30px; line-height: 41px; }
  .reviews-list__item-info div span { font-size: 25px; line-height: 34px; }
  .reviews-list__item-info p { font-size: 20px; font-weight: 600; line-height: 27px; } }

.registration { padding: 50px 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.registration-info { display: flex; flex-direction: column; gap: 20px; }

.registration-info p { font-size: 18px; line-height: 25px; }

.registration-info p:first-child { font-weight: 500; }

.registration-info p:last-child { color: var(--primary-500); font-weight: 700; }

.registration-img div { position: relative; margin-top: 55px; margin-bottom: 69px; width: 248.71px; height: 248.71px; border-radius: 50%; background-color: var(--white); aspect-ratio: 1 / 1; }

.registration-img div img { position: absolute; top: -42px; left: -13px; width: 276.75px; }

@media only screen and (min-width: 1024px) { .registration { padding: 100px 0 68px; flex-direction: row; align-items: flex-start; justify-content: space-between; flex-wrap: wrap; width: 100%; }
  .registration-info { margin-top: 66px; width: 60%; flex-shrink: 0; gap: 30px; }
  .registration-info p { font-size: 30px; line-height: 41px; }
  .registration-info p:first-child { font-weight: 400; }
  .registration-img { width: 40%; display: flex; align-items: center; justify-content: center; }
  .registration-img div { margin-top: 0; margin-bottom: 0; width: 390px; height: 390px; }
  .registration-img div img { top: -80px; left: -18px; width: 427.75px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .registration-img { width: 37%; }
  .registration-img div { width: 559.9px; height: 559.9px; }
  .registration-img div img { width: 623.02px; height: 718.09px; } }

@media only screen and (min-width: 1024px) { .registration .btn { margin-top: -28px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .registration .btn { margin-top: -195px; } }

.footer { padding: 50px 0; display: flex; align-items: center; justify-content: center; }

.footer-wrapper { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.footer-wrapper img { width: 118.13px; height: 36.76px; }

.footer-wrapper span { margin-top: 40px; font-size: 18px; font-weight: 600; line-height: 25px; color: var(--white); }

.footer-wrapper__list { margin-top: 50px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; padding: 0 9px; }

.footer-wrapper__list li { text-align: center; }

.footer-wrapper__list li a { font-size: 16px; font-weight: 600; line-height: 22px; color: var(--white); border-bottom: 1px solid #fff; }

@media only screen and (min-width: 767px) { .footer { padding: 99px 0 106px; }
  .footer-wrapper img { width: 203.88px; height: 63.45px; }
  .footer-wrapper span { font-size: 20px; line-height: 27px; }
  .footer-wrapper__list li a { font-size: 25px; line-height: 34px; } }

.btn { display: flex; align-items: center; justify-content: center; padding: 13px 0; width: 100%; height: 65px; border-radius: 100px; font-size: 18px; font-weight: 700; line-height: 25px; text-decoration: none; transition: .3s; }

.btn--primary { background-color: var(--primary-500); color: var(--white); }

.btn--primary:hover { background-color: #8D0310; }

.btn--primary.disabled { background-color: #C93F4C; cursor: none; pointer-events: none; }

@media only screen and (min-width: 1024px) { .btn { width: fit-content; padding: 13px 50px; } }

@media only screen and (min-width: 1920px) { .btn { min-width: 621px; width: unset; height: 101px; font-size: 30px; line-height: 41px; } }
