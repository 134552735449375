.registration {
    padding: 50px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-info {
        display: flex;
        flex-direction: column;

        gap: 20px;

        p {
            font-size: 18px;
            line-height: 25px;
        }

        p:first-child {
            font-weight: 500;
        }
        p:last-child {
            color: var(--primary-500);
            font-weight: 700;
        }
    }

    &-img {
        div {
            position: relative;
            margin-top: 55px;
            margin-bottom: 69px;
    
            width: 248.71px;
            height: 248.71px;
            border-radius: 50%;
            background-color: var(--white);
            aspect-ratio: 1 / 1;
        }

        div img {
            position: absolute;
            top: -42px;
            left: -13px;

            width: 276.75px;
        }
    }
}

@include rmin($desktop) {
    .registration {
        padding: 100px 0 68px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        &-info {
            margin-top: 66px;
            width: 60%;
            flex-shrink: 0;
            gap: 30px;

            p {
                font-size: 30px;
                line-height: 41px;
            }

            p:first-child {
                font-weight: 400;
            }
        }

        &-img {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                margin-top: 0;
                margin-bottom: 0;
                width: 390px;
                height: 390px;
            }

            div img {
                top: -80px;
                left: -18px;
                width: 427.75px;
            }

            @include rmin($desktop-lg) {
                width: 37%;

                div {
                    width: 559.9px;
                    height: 559.9px;
                }

                div img {
                    width: 623.02px;
                    height: 718.09px;
                }
            }
        }

        .btn {
            margin-top: -28px;

            @include rmin($desktop-lg) {
                margin-top: -195px;
            }
        }
    }
}