.footer {
    padding: 50px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 118.13px;
            height: 36.76px
        }

        span {
            margin-top: 40px;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            color: var(--white);
        }
    }

    &-wrapper__list {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0 9px;

        li {
            text-align: center;
        }

        li a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: var(--white);
            border-bottom: 1px solid #fff;
        }
    }
}

@include rmin($tablet) {
    .footer {
        padding: 99px 0 106px;

        &-wrapper {
            img {
                width: 203.88px;
                height: 63.45px;
            }

            span {
                font-size: 20px;
                line-height: 27px;
            }
        }

        &-wrapper__list {
            li a {
                font-size: 25px;
                line-height: 34px;
            }
        }
    }
}