// breakpoints
$phone-s: 375;
$phone-m: 393;
$phone-lg: 414;
$phone-lg-2: 546;
$tablet: 767;
$desktop-xs: 940;
$desktop: 1024;
$desktop-lg: 1920;

// fonts
$font-main: "ns", sans-serif;

$max-width: 1529px;

:root {
    --black: #000000;
    --white: #FFFFFF;

    --primary-500: #AB212E;
    --hover: #8D0310;

    --light: #F5F5F4;

    --black-100: #1E1C1C;
    --yellow: #FFD503;

    // border radius
    --br--3: 3px;
    --br--5: 5px;
    --br--7: 7px;
    --br--6: 6px;
    --br--8: 8px;
    --br--10: 10px;
    --br--12: 12px;
    --br--15: 15px;
    --br--16: 16px;
    --br--20: 20px;
    --br--25: 25px;
    --br--30: 30px;
    --br--66: 66px;
}