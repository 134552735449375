.hero {
    position: relative;
    margin-top: -77px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 126px 0 50px;

    overflow: hidden;

    &>* {
        padding: 0 26px;
        color: var(--white);
        text-decoration: none;
        z-index: 2;
    }

    &-top {
        text-align: center;

        p {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            text-transform: inherit;
        }
    }

    &-title {
        span {
            display: none;
        }

        h1 {
            margin-top: 50px;
            text-align: center;
            font-size: 30px;
            font-weight: 800;
            line-height: 41px;
        }
    }

    &-wrapper {
        margin-top: 50px;
        padding: unset;

        .btn {
            margin: auto;
            width: 85.3%;
        }

        &__list {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 26px;
        }

        &__list li {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            img {
                margin-top: 2.5px;
                display: flex;
                flex-shrink: 0;
                width: 19px;
                height: 20px;
            }

            span {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
            }
        }

        &__info {
            position: relative;
            margin-top: 56px;

            display: flex;
            align-items: flex-start;
            padding: 20px 33px 22px 21px;
            background-color: var(--white);
        }

        &__info-descr {
            display: flex;
            flex-direction: column;
            gap: 15px;

            p {
                font-size: 18px;
                line-height: 25px;
            }

            p:first-child {
                padding-right: 100px;
                font-weight: 600;
                color: var(--black-100);

                @include rmin($phone-lg-2) {
                    padding-right: 110px;
                }
            }

            p:last-child {
                font-weight: 800;
                color: var(--primary-500);

                @include rmin($phone-lg) {
                    padding-right: 110px;
                }
            }
        }

        &__info-img {
            position: absolute;

            top: -70px;
            right: -16px;

            width: 185.81px;
            height: 214.16px;

            img {
                width: 100%;
            }
        }
    }

    &-bottom {
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 115px;
            height: 31.25px;
        }

        &>span {
            text-align: center;
            margin-top: 50px;
            font-size: 20px;
            font-weight: 600;
            line-height: 27px;
        }

        &__timer {
            margin-top: 20px;

            &>span {
                font-size: 40px;
                font-weight: 600;
                line-height: 55px;
            }

            img {
                display: none;
            }
        }

        .btn {
            margin-top: 30px;
        }
    }

    &-bg {
        position: absolute;

        top: -45px;
        left: -51px;

        width: 1214.45px;
        height: 1278.58px;
        padding: unset;

        z-index: 1;

        img {
            width: 100%;
        }
    }
}

@include rmin($tablet) {
    .hero {
        &-wrapper {
            padding: 0  26px;

            .btn {
                display: none;
            }

            &__list {
                padding: unset;
            }

            &__info {
                padding: 39px 230px 43px 80px;
                border-radius: 150px;
            }

            &__info-descr p {
                padding-right: 0;
            }
        }
    }
}

@include rmin($desktop) {
    .hero {
        margin-top: -102px;

        align-items: flex-start;
        justify-content: flex-start;

        padding: 64px 0 50px;

        &-top {
            align-self: flex-end;
            padding: 0 30px 0 250px;
            
            p {
                text-align: end;
                text-transform: none;
                font-size: 40px;
                font-weight: 600;
                line-height: 55px;
            }

            span {
                display: none;
            }
        }

        &-title {
            margin-top: 1px;
            span {
                display: flex;

                font-size: 30px;
                font-weight: 700;
                line-height: 41px;
            }

            h1 {
                margin-top: 42px;
                text-align: start;
                font-size: 50px;
                font-weight: 700;
                line-height: 72px;
            }
        }

        &-wrapper {
            margin-top: 67px;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            &__info {
                margin-top: 0;
                width: 50%;
            }

            &__info-descr {
                p:last-child {
                    padding-right: 0;
                }
            }
            &__info-img {
                width: 231px;
            }

            &__list {
                margin-top: 0;

                li {
                    align-items: center;
                }

                li img {
                    width: 34px;
                    height: 34px;
                }
            }
        }

        &-bottom {
            margin-top: 70px;
            flex-direction: row-reverse;
            gap: 20px;

            &__arrow {
                display: none;
            }

            &__timer,
            span,
            .btn {
                margin-top: 0;
            }

            span {
                text-align: start;
            }

            &__timer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    display: flex;
                }

                .arrow-white {
                    margin-top: 10px;
                    width: 100%;
                }

                .arrow-red {
                    margin-top: 12px;
                    width: 100%;
                }
            }
        }

        &-bg {
            width: 1114.87px;
            height: 1157.22px;
            top: -4px;
            right: -138px;
            left: unset;
        }
    }
}

@include rmin($desktop-lg) {
    .hero {
        overflow: hidden;

        &-top {
            padding: 0 0 0 300px;
        }

        &-title {
            padding: unset;

            h1 {
                font-size: 60px;
                font-weight: 800;
                line-height: 82px;
            }
        }

        &-wrapper {
            padding: unset;

            gap: 123px;
        }

        &-wrapper__info {
            min-width: 800px;
            padding: 39px 164px 43px 80px;

            &-descr {
                p {
                    font-size: 24px;
                    line-height: 33px;
                }

                p:first-child {
                    padding-right: 0;
                }
                p:last-child {
                    padding-right: 50px;
                }
            }

            &-img {
                width: 310.3px;
                height: 357.65px;

                top: -116px;
                right: -32px;
            }
        }

        &-wrapper__list {
            margin-top: 17px;
            gap: 12px;

            li span {
                font-size: 30px;
                font-weight: 400;
                line-height: 41px;   
            }
        }

        &-bottom {
            padding: unset;
            align-items: flex-start;
            gap: 0;

            &__timer {
                margin: 18px 0 0 77px;

                .arrow-white {
                    margin-top: 0;
                    width: 133px;
                }

                .arrow-red {
                    width: 115px;
                }
            }

            &>span {
                margin: 9.5px 0 0 52px;
                padding-right: 118px;
                font-size: 30px;
                font-weight: 300;
                line-height: 41px;
            }
        }
    }
}