.speaker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 50px 0;

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-wrapper__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 5px;

        span {
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            text-align: center;
        }

        h2 {
            font-size: 25px;
            font-weight: 700;
            line-height: 34px;
            padding: 10px;
            border-bottom: 3px solid var(--primary-500);
        }
    }

    &-wrapper__img {
        position: relative;
        margin-top: 20px;

        width: 100%;

        img {
            width: 100%;
            object-fit: cover;
        }

        &::before {
            position: absolute;

            content: '';

            bottom: 0;
            left: 0;
            width: 55%;
            height: 90%;
            background-color: var(--primary-500);
            z-index: -1;
        }

        &::after {
            position: absolute;

            content: '';

            bottom: 0;
            left: 0;

            width: 100%;
            height: 33px;
            background-color: #D9D9D9;
        }

        @include rmin($phone-lg-2) {
            display: flex;

            img {
                margin: auto;
                max-width: 509px;
            }
        }
    }

    &-wrapper__list {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-wrapper__list-item {
        position: relative;
        margin-left: 25px;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;

        &::before {
            position: absolute;

            content: '';

            top: 7px;
            left: -25px;

            width: 10px;
            height: 10px;
            background-color: var(--primary-500);
            border-radius: 50%;
        }
    }

    &-list {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        &__item {
            display: flex;
            flex-direction: column;

            padding: 27px 30px;

            border: 3px solid var(--primary-500);
            border-radius: 17.83px;
            gap: 9px;

            span {
                font-size: 30px;
                font-weight: 700;
                line-height: 41px;
            }

            p {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
            }
        }
    }
}


@include rmin($desktop) {
    .speaker {
        &-wrapper {
            max-width: 1200px;
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-end;
        }

        &-wrapper__title {
            position: absolute;

            top: 0;
            left: 50%;

            &.section {
                padding: unset;
            }
        }

        &-wrapper__list {
            margin-top: 99px;
            padding-right: 20px !important;
            gap: 15px;
            order: 2;
            width: 50%;

            &.section {
                padding: unset;
            }
        }

        &-wrapper__img {
            order: 1;
            width: 50%;

            &::after {
                width: 200%;
            }
        }

        &-list {
            flex-direction: row;
        }
    }
}

@include rmin($desktop-lg) {
    .speaker {
        padding: 43px 0 99px;

        &-wrapper {
            max-width: unset;
            justify-content: flex-start;
            align-items: flex-start;

            &__img {
                margin-top: 0;
                width: 804.51px;

                img {
                    max-width: unset;
                }

                &::after {
                    width: 190.1%;
                    height: 59px;
                }
            }

            &__title {
                margin-top: 58px;
                gap: 10px;
                left: 52.7%;

                span {
                    font-size: 30px;
                    line-height: 41px;
                }

                h2 {
                    font-size: 42px;
                    line-height: 57px;
                }
            }

            &__list {
                margin-top: 270px;
                width: 45.6%;

                gap: 30px;

                &-item {
                    margin-left: 59px;

                    font-size: 20px;
                    line-height: 27px;
                }

                &-item::before {
                    width: 20px;
                    height: 20px;
                    top: 10px;
                    left: -59px;
                }
            }
        }

        &-list {
            margin-top: 60px;
            width: 100%;
            gap: 30px;

            &.section {
                padding: unset;
            }

            &__item {
                padding: 31px 22px 30px 34px;
                gap: 11px;
                
                min-width: 360px;
                border-radius: 20px;

                span {
                    font-size: 42px;
                    line-height: 57px;
                }

                p {
                    font-size: 25px;
                    line-height: 34px
                }
            }
        }
    }
}