.header {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 40px 0 0;

    &-wrapper__logo {
        width: 118.13px;
        height: 36.76px;

        img {
            width: 100%;
        }
    }
}

@include rmin($desktop) {
    .header {
        align-items: flex-start;
        justify-content: flex-start;

        margin: auto;
        max-width: 1530px;

        padding: 40px 35px 0;

        &-wrapper__logo {
            width: 198px;
            height: 61.62px;
        }

        @include rmin($desktop-lg) {
            padding: 40px 0 0;
        }
    }
}