body {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    font-family: $font-main;
    color: var(--black-100);
    background-color: var(--white);

    &.lock {
        overflow: hidden;
    }
}

.container {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: unset;
}

.section {
    padding-left: 26px;
    padding-right: 26px;

    &--filled {
        background-color: var(--light);
    }

    &--filled-black {
        background-color: var(--black-100);
    }

    &--filled-primary {
        background-color: var(--primary-500);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 500;
    }

    a {
        text-decoration: none;
    }
}