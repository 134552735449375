.advantages {
    padding: 40px 0 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        position: relative;
        padding: 0 10px 20px;
        font-size: 25px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        border-bottom: 3px solid var(--primary-500); 
    }

    &-list {
        margin-top: 56px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 30px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                width: 70px;
                height: 70px;

                background-color: var(--yellow);
                border-radius: 50%;
            }

            div img {
                width: 51.75px;
                height: 51.75px;
            }

            span {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
            }
        }
    }
}

@include rmin($tablet) {
    .advantages {
        align-items: flex-start;

        &-list {
            margin-top: 110px;
            margin-bottom: 100px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
}

@include rmin($desktop) {
    .advantages {
        padding: 100px 0 123px;

        h2 {
            font-size: 42px;
            line-height: 57px;
            padding: 10px;

            &::after {
                bottom: -10px;
            }
        }

        &-list {
            column-gap: 52px;

            @include rmin($desktop-lg) {
                column-gap: 183px;
                row-gap: 60px;
            }
        }

        &-list__item {
            align-items: flex-start;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;

            div {
                width: 100px;
                height: 100px;

                img {
                    width: 72px;
                    height: 72px;
                }
            }

            span {
                text-align: start;
                font-size: 20px;
                line-height: 31px;
            }

            @include rmin($desktop-lg) {
                gap: 60px;

                span {
                    padding-right: 90px;
                    font-size: 30px;
                    line-height: 41px;
                }
            }
        }
    }
}